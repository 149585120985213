/* grid areas */

.blockLeft {
  grid-area: contentLeft;
}

.blockCentre {
  grid-area: contentCentre;
}

.blockRight {
  grid-area: contentRight;
}

.homeContactLeft {
  grid-area: homeContactLeft;
}

.homeContactRight {
  grid-area: homeContactRight;
}

.piercingsLeft {
  grid-area: piercingsLeftBlock;
}

.piercingsCentre {
  grid-area: piercingsCentreBlock;
}

.piercingsRight {
  grid-area: piercingsRightBlock;
}

.homeFeatureLeft {
  grid-area: homeFeatureLeft;
}

.homeFeatureRight {
  grid-area: homeFeatureRight;
}

.homeProfileLeft {
  grid-area: homeProfileLeft;
}

.homeProfileRight {
  grid-area: homeProfileRight;
}

.homeJewelleryOne {
  grid-area: homeJewelleryOne;
}

.homeJewelleryTwo {
  grid-area: homeJewelleryTwo;
}

.homeJewelleryThree {
  grid-area: homeJewelleryThree;
}

.homeJewelleryFour {
  grid-area: homeJewelleryFour;
}

.leftHalf {
  grid-area: leftHalf;
}

.rightHalf {
  grid-area: rightHalf;
}

.leftThird {
  grid-area: leftThird;
}

.centreThird {
  grid-area: centreThird;
}

.rightThird {
  grid-area: rightThird;
}

.headerFeatureLeft {
  grid-area: headerFeatureLeft;
}

.headerFeatureRight {
  grid-area: headerFeatureRight;
}

.contactLeft {
  grid-area: contactLeft;
}

.contactRight {
  grid-area: contactRight;
}

.navigation {
  grid-area: nav;
}

.mainBody, .homeContact {
  box-sizing: border-box;
}

/* navigation and header */
.navigation {
  text-align: right;
  margin-bottom:3rem;
  text-shadow: 0.3rem 0.3rem 0.5rem #000;
}

.navigation li{
  display:inline;
  border-right:#c1b078 solid 1px;
}

.navigation a, .footerBlock a, .contact a, a {
  color:#FFF;
  font-weight:bold;
  text-decoration: none;
}

.navigation a:hover, .footerBlock a:hover, .contact a:hover, a:hover {
  color:#c1b078;
}

.logoImage, .logoImageSmall {
text-align: center;
}

.headerBlock {
  padding: 2rem;
  background-color: rgba(0,0,0,0.75);
  border-bottom: #a08d5e 5px solid;
  box-shadow: 1.5rem 1.5rem 1.5rem #000;
}

/* footer */

.footerBlock {
  background-color: rgba(0,0,0,0.7);
  padding:2rem; 
}

.footerBlock {
  text-align: center;
}

/* page layouts and general styling */

.backgroundVideo {
  z-index: -1000;
  position:fixed;
  min-height:100%;
  background-size: cover;
  min-width:100%;
}

.componentBlock {
clear:both;
margin-bottom:2rem;
}

.hrule {
  max-width:50%;
  margin:0;
}

.iframeContainer {
  text-align: center;
}

.contactLeft img {
  padding-top: 1rem;
}

/* typography */

.introText h3 {
  border-bottom: #a08d5e solid 2px;
  padding-bottom:3rem;
}

.headerFeatureLeft p {
  text-align: center;
}

p {
  line-height: 2rem;
  font-size: 1rem;
}

h2, h4 {
  text-align: center;
}

.contact h2 {
  text-align: left;
}

.halves li {
  line-height: 2rem;
}

.faqBlock p {
  padding-bottom: 2rem;
  border-bottom: #a08d5e solid 2px;
}

.faqBlock h3 {
  padding-top:1rem;
}

.faqPage h1 {
  text-align: center;
}

.bookingsPage h1, .bookingsPage h3 {
  text-align: center;
}

.bookingsPage h3 {
  padding-bottom: 1rem;
}

.homeContact h2 {
  text-align: left;
}

.centre {
  text-align: center;
}

/* page elements */

.button a, .homeContact .button, .contact .button, .bookingsPage .button {
  font-weight:bold;
  background-image: linear-gradient(180deg,#f1e9a0, #a08d5e);
  border-radius: 0.5rem;
  border:2px solid #927e53;
  width:fit-content;
  padding: 0.5rem 3rem;
  margin: 1rem auto;
  color:#000;
  display:inline-block;
}

.button a:hover, .homeContact .button:hover, .bookingsPage .button {
  background-image: linear-gradient(180deg, #a08d5e, #f1e9a0);
  cursor: pointer;
}

/* home page */

.introText {
  text-align: center;
  line-height: 2rem;
}

.homeContact {
  background-image: url('../src/img/shopMockUp2.png');
  background-position: right;
  background-size: cover;
  padding:2rem;
  border-top: #a08d5e solid 2px;
  width:100%;
}

.homeContact input, .homeContact textarea, .contact input, .contact textarea, .homeContact select, .contact select  {
  width:100%;
  padding: 1rem;
  box-sizing: border-box;
}


.homeContact button, .contact button {
  font-size: 1rem;
  line-height: 2rem;
}

.formLabel {
  display:block;
}

.homeContentImage {
  text-align: center;
  padding:2rem;
}

.homeBlocks {
  text-align: center;
  border-bottom: #a08d5e solid 2px;
}

.homeJewelleryFeature {
  text-align: center;
}

.homeJewelleryItem img {
  padding-top:2rem;
}


/* mobile view */
@media (max-width: 55rem) {

  .mobileView {
    display:block;
  }

  .headerFeatureRight {
    margin-left:8rem;
  }

  .mobileNavigation {
    overflow: hidden;
    width:100%;
    max-width:20rem;
    text-align: right;
  }

  .dropdown {
    overflow:hidden;
    text-align: right;
  }

  .dropdown-content {
    display:none;
    position:absolute;
    float:right;
  }

  .mobileNavigation li{
    background-color: rgba(0,0,0,0.7);
    border-bottom: #a08d5e solid 2px;
    text-align: right;
    padding:1.1rem;
    list-style-type: none;
  }

  .desktopView, .navigation {
    display:none;
  }

  .headerBlock {
    display:grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "headerFeatureLeft headerFeatureRight";
  }

.bodyContainer {
  color:white;
}

.mainBody {
  max-width: 48rem;
  width:100%;
  padding:2rem 2rem 0 2rem;
  margin: 0 auto;
}

.largeLogo {
  display:none;
}

.smallLogo {
  display:block;
}

.homeProfile {
  background-color: rgba(0,0,0,0.7);
  border-bottom: #a08d5e solid 2px;
  padding:2rem;
}

.homeProfile img {
  max-width: 30rem;
}

.homeJewelleryItem img {
  max-width: 19rem;
  padding-top: 2rem;
}

.homeContact input, .homeContact textarea, .contact input, .contact textarea {
  clear:both;
  width:100%;
  padding: 1rem;
  display:block;
}

.homeContact select, .contact select {
  width:100%;
  padding:1rem;
  display:block;
}

.homeContact .button {
  width:100%;
}

.piercingsLeft img, .piercingsRight img, .leftHalf img, .rightHalf img, .homeProfileLeft img {
  width:100%;
}

.leftHalf, .rightHalf, .jewelleryBlock {
  background-color: rgba(0,0,0,0.7);
  padding: 0 3rem 1rem 3rem;
}

.leftThird .image, .centreThird .image, .rightThird .image {
  padding-top:2rem;
}

.jewelleryBlock, .jewelleryHeading {
  text-align: center;
}

.bookingsPage select, .bookingsPage .bookingsFormInput, .bookingsFormInput input  {
  width:100%;
  padding: 1rem;
  box-sizing: border-box;
}

.bookingsPage hr {
  margin-top: 2rem;
}

.bookingsPage .button {
  width:100%;
}
.auppLeft {
  display:none;
}

.auppRight img {
  width:100%;
  padding-top:1rem;
}

.auppRight {
  border-top: solid 1px #FFF;
}

}

/* tablet view */
@media (min-width: 55rem) {

  .mobileView, .mobileNavigation {
    display:none;
  }

  .desktopView {
    display:block;
  }

  .navigation li {
    font-size: 0.8rem;
    padding:0.5rem;
    
  }

  .headerBlock {
    display:grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
    "headerFeatureLeft nav"
    "headerFeatureLeft headerFeatureRight";
  }
  .bodyContainer {
    color:white;
  }

  .mainBody {
    max-width: 70rem;
  }

  .largeLogo {
    display:none;
  }
  
  .smallLogo {
    display:block;
  }
  .homeFeature {
    display:grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "homeFeatureLeft homeFeatureRight";
  }

  .homeBlocks {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "contentLeft contentCentre contentRight";
    gap: 2rem;
  }

  .homeProfile {
    background-color: rgba(0,0,0,0.7);
    border-bottom: #a08d5e solid 2px;
    display:grid;
    gap: 2rem;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: "homeProfileLeft homeProfileRight";
    padding:2rem 2rem 0 2rem;
  }

  .homeProfile img {
    padding-top: 0.5rem;
  }

  .mapFooter {
    width:100%;
    height:40rem;
  }

  .homeContentBlock {
    padding:2rem 4rem;
  }

  .homeBlocks .homeContentBlock .homeContentImage img {
    max-width: 10rem;
  }

  .homeJewelleryItem {
    padding-bottom:2rem;
  }

  .homeContact input, .homeContact textarea, .contact input, .contact textarea {
    clear:both;
    width:60%;
    padding: 1rem;
    display:block;
  }
  
  .homeContact select, .contact select {
    width:30%;
    padding:1rem;
    display:block;
  }

  .contact {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "contactLeft contactRight";
    gap: 2rem;
  }

  .piercingsPageBlocks {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "piercingsLeftBlock piercingsCentreBlock piercingsRightBlock";
    gap: 1rem;
  }

  .homeJewelleryFeature {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "homeJewelleryOne homeJewelleryTwo homeJewelleryThree homeJewelleryFour";
  }

  .halves {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "leftHalf rightHalf";
    gap:4rem;
    padding:2rem;
  }

  .leftHalf, .rightHalf {
    background-color: rgba(0,0,0,0.7);
    padding: 3rem;
  }

  .jewelleryBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "leftThird centreThird rightThird";
    gap: 2rem;
    padding: 2rem;
    background-color: rgba(0,0,0,0.7);
  }

  .jewelleryBlock p {
    padding-bottom: 2rem;
    border-bottom: #a08d5e 2px solid;
    min-height:12rem;
  }

  .piercingsLeft .image, .piercingsRight .image {
    text-align: center;
  }

  .piercingsPageBlocks {
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "piercingsLeftBlock piercingsRightBlock";
    gap: 1rem;
  }


  .halves .image {
    text-align: center;
    border-bottom: none;
    margin-top: 2rem;
  }

  .piercingsPageIntro {
    text-align: center;
  }

  .piercingsPageBlocks {
    border-bottom: #a08d5e 2px solid;
  }

  .piercingsPageOutro {
    border-top: #a08d5e 2px solid;
  }

  .homeContact {
    min-height: 40rem;
    background-position: top right;
  }

  .bookingsPage form {
    width:60%;
    margin:0 auto;
  }

  .bookingsPage .formLabel {
    text-align: left;
  }

  .bookingsPage select, .bookingsPage .bookingsFormInput, .bookingsFormInput input {
    min-width: 25rem;
  }

  .bookingsPage input, .bookingsPage select, .bookingsPage textarea {
    padding:1rem;
  }

  .bookingsPage textarea {
    min-height: 6rem;
  }

  .bookingsPage .bookingsFormInput {
    width:100%;
  }

  .bookingsPage .radioButton {
    width:1rem !important;
  }

  .bookingsPage hr {
    margin-top: 2rem;
  }

  .auppLeft {
    display:block;
  }

  .auppRight {
    display:none;
  }

  /* faq page */

.faqPage .leftHalf, .faqPage .rightHalf {
  padding-top:0;
}

}

/* small desktop view */
@media (min-width: 65rem) {

  .navigation li {
    padding:0.5rem;
  }

}

/* desktop view */
@media (min-width: 75rem) {

  .navigation li {
    font-size: 1rem;
    padding:1rem;
  }

  .homeBlocks .homeContentBlock .homeContentImage img {
    max-width: 20rem;
  }
 
  .mainBody {
    max-width: 120rem;
    width:100%;
    padding:2rem 2rem 0 2rem;
    margin: 0 auto;
  }

  .largeLogo {
    display:block;
  }

  .smallLogo {
    display:none;
  }

  .jewelleryHeading {
    text-align: center;
  }

  .jewelleryBlock p {
    padding-bottom: 2rem;
    border-bottom: #a08d5e 2px solid;
  }

  .jewelleryBlock .image {
    text-align: center;
    border-bottom: none;
    margin-top: 2rem;
  }

  .halves .image {
    text-align: center;
    border-bottom: none;
  }

  .bookingsPage .timeSlot {
    margin-left: 1.5rem;
  }



}

/* large screen */
@media (min-width: 125rem) {

  .navigation li {
    font-size: 1rem;
    padding:1rem 2rem;
  }

  .mainBody {
    max-width: 120rem;
  
  }

}


